/* eslint-disable @next/next/link-passhref */
import { useRouter } from 'next/router';
import Link from 'next/link';
import classes from './_404.module.scss';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export default function Error404() {
  const router = useRouter();

  const pages = [
    'homepage',
    'prospect',
    'reveal',
    'payment',
    'workspace-settings',
    'engage',
    'integration',
    'automation',
    'profile',
    'invite',
    'onboarding',
    'icp',
    'enrich',
    'edit-profile',
    'blacklisted-companies',
    'account',
    'setup-options',
    'setup-tracking-script',
    'workspace-settings',
    'websites',
    'tags',
    'website-personalisation',
    'session-player',
    'prospector',
  ];
  const { userInfo } = useSelector(({ auth }) => auth);
  const [currentWebsiteId, setCurrentWebsiteId] = useState('');

  useEffect(() => {
    if (Object.keys(userInfo)?.length > 0) {
      setCurrentWebsiteId(userInfo.websites[0]?._id);
    } else {
      const id = typeof window !== 'undefined' ? localStorage.getItem('currentWebsiteId') : '';
      setCurrentWebsiteId(id);
    }
  }, [userInfo]);

  useEffect(() => {
    if (currentWebsiteId?.length > 0) {
      if (pages.includes(router.asPath?.split('/')[1])) {
        router.push(`/${router.asPath?.split('/')[1]}/${currentWebsiteId}`);
      } else {
        // if we don't have that page
        router.push(`/homepage/${currentWebsiteId}`);
      }
    } else if (currentWebsiteId?.length == 0 && Object.keys(userInfo)?.length <= 0) {
      router.push('/signin');
    }
  }, [currentWebsiteId, userInfo]);

  return (
    <>
      <div className={classes.container}>
        <div className={`animated zoomInDown ${classes.image}`}>
          <Image src="/images/4041.svg" width={610} height={380} />
        </div>
        <div className={` zoomIn animation-delay-10 animated ${classes.text}`}>
          <p className={classes.blueTxt}>Oops....! There seems to be a problem here.</p>
          <p>Either the URL was mistyped, never existed or once did but mysteriously disappeared😅</p>
          <div className={classes.btn}>
            <button onClick={() => router.back()}>Return to previous page</button>
            <Link href="/" shallow>
              <button className={classes.darkBtn}>Go to home screen</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
